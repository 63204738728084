.custom-date-picker {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: white;
    color: #333;
    cursor: pointer;
    transition: border-color 0.2s;
}

.custom-date-picker:hover {
    border-color: black;
}

.react-datepicker__input-container {
    display: inline-block;
    width: 100%;
}